import axios from "axios";
import { createEffect } from "effector";

export const baseUrl = process.env.REACT_APP_BACKEND_SERVER_HOST
  ? process.env.REACT_APP_BACKEND_SERVER_HOST
  : "";

export interface FilterBeforeSendType {
    from?: string
    to?: string 
    id?: string
    name?: string
    email?: string
    car_number?: string
    code?: string
    admin?: boolean
    subscribed?: boolean
}

interface FiltersApiType {
    data: FilterBeforeSendType | null
    page: number
}

export const fetchFilterUserFx = createEffect(async ({data, page}:FiltersApiType) => {
    const req = await axios({
      method: "POST",
      url: `${baseUrl}/adm/statistic/user/list?page=${page}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({...data, per_page: 30}),
      withCredentials: true,
    });
  
    return req.data;
});