import axios from "axios";
import { createEffect } from "effector";

export const baseUrl = process.env.REACT_APP_BACKEND_SERVER_HOST
  ? process.env.REACT_APP_BACKEND_SERVER_HOST
  : "";

export interface authorization {
    email: string;
    password: string;
}

export const csrfTokenFx = createEffect(() => {
    const req = fetch(`${baseUrl}/sanctum/csrf-cookie/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    });
  });


const auth = async (params: authorization) => {
  const req = await axios({
    method: "POST",
    url: `${baseUrl}/back/login/`,
    headers: {
    "Content-Type": "application/json",
    },
    data: JSON.stringify({ email: params.email, password: params.password }),
    withCredentials: true,
  });
  return req;
};

export const authFx = createEffect(auth);

export const fetchUserFx = createEffect(async () => {
    const req = await axios({
      method: "GET",
      url: `${baseUrl}/back/user`,
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true,
    });
  
    return req.data.data;
});

export const fetchEmailFx = createEffect(async (email: string) => {
    const req = await axios({
      method: "POST",
      url: `${baseUrl}/adm/email/all`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify({email: email}),
      withCredentials: true,
    });
  
    return req.data.data;
});