import { createEffect, createEvent, createStore } from "effector";
import { Filter, changeForm } from "./sort";
import { fetchFilterUserFx } from "../api/statistic";
import { fetchFilterInvoiceFx } from "../api/invoicesStatistic";


interface InvoicesDuePayments {
    amount: string
    bill_date: string
    bill_number: string
    created_at: string
    department_name: string
    discount: string
    discount_till: string
    document_id: number
    full_name: string
    id: string
    legal_act: string
    offense_date: string
    offense_place: string
    paid: boolean
    paid_by_user: boolean
}

export interface InvoicesType {
    id: string
    commission: number
    created_at: string
    updated_at: string
    due_payments: InvoicesDuePayments[]
    invoice_status_id: string
    payment: string
    payment_id: string
    status: string
    subtotal: string
    user: StatisticsUserType
}

interface StatisticsUserType {
    id: number
    name: string
    email: string
    admin: boolean
    two_factor: boolean
    paid_with_us: number
    created_at: string
    invoices: InvoicesType[]
    updated_at: string
    documents: DocumentCarType[]
}

export interface DocumentCarType {
    approved: boolean
    car_number: string | null
    code: string
    driver_name: string
    due_payments: DuePayments[]
    focus: boolean
    id: string
    subscribed: boolean
    type_id: number
    type_name: string
}

export interface DuePayments {
    id: number,
    bill_number: string,
    paid: boolean,
    paid_by_user: boolean,
    amount: string
    discount: string
    discount_till: string
    full_name: string
    bill_date: string
    offense_place: string | null
    legal_act: string | null
    offense_date: string | null
    department_name: string | null
    images: any[]
}

export interface TablePaginationType {
    current_page: number,
    from: number,
    last_page: number,
    total: number 
}

interface StatisticsTableType {
    data: StatisticsUserType[]
    meta: TablePaginationType
}

interface StatisticsInvoices {
    data: InvoicesType[]
    meta: TablePaginationType
}

export const updateStatisticsUser = createEvent()

export const $statisticsUser = createStore<StatisticsTableType | null>(null)
 .on(
    fetchFilterUserFx.doneData,
    (state, result) => {
        return result
    }
  );

export const $statisticsInvoices = createStore<StatisticsInvoices | null>(null)
 .on(
    fetchFilterInvoiceFx.doneData,
    (state, result) => {
        return result
    }
  );