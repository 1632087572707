import { Navigate, Outlet, useLocation } from "react-router-dom";
import { $user } from "../../store/user";
import { useUnit } from "effector-react";
import { fetchUserFx } from "../../api/user";
import React from "react";
import { StoreWritable } from "effector";

const PrivateRoutes = () => {
  const location = useLocation();
  const user = useUnit($user);

  return user ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};

export default PrivateRoutes;
