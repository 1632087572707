import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Tooltip,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { StyledTableCell } from "../../page/dashboard/ui";
import { DocumentCarType, InvoicesType } from "../../store/statisticsUser";
import { DateTime } from "luxon";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArticleIcon from "@mui/icons-material/Article";

interface DocumentsDialogType {
  dataInvoces: InvoicesType[];
  setInvoices: (val: boolean) => void;
}

const InvoicesDrawler = ({ dataInvoces, setInvoices }: DocumentsDialogType) => {
  const [anchorEl, setAnchorEl] = React.useState<null | any>(null);

  const open = Boolean(anchorEl);
  const handleClick = (
    id: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl((val: any) => {
      return { ...val, [id]: event.currentTarget };
    });
  };
  const handleClose = (id: string, billNumber: string | null) => {
    if (billNumber) navigator.clipboard.writeText(billNumber);
    setAnchorEl((val: any) => {
      return { ...val, [id]: null };
    });
  };

  React.useEffect(() => {
    if (dataInvoces) {
      const list: any = {};
      dataInvoces.forEach((item) => {
        list[item.id] = null;
      });
      setAnchorEl(list);
    }
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 850 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell align="center" sx={{ maxWidth: "150px" }}>
                Статус
              </StyledTableCell>
              <StyledTableCell align="center">Сумма</StyledTableCell>
              <StyledTableCell align="left">Коммисия</StyledTableCell>
              <StyledTableCell align="left">Штрафы</StyledTableCell>
              <StyledTableCell align="left">Платежка</StyledTableCell>
              <StyledTableCell align="center">Платежный ID</StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ whiteSpace: "nowrap", maxWidth: "150px" }}
              >
                Дата
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {anchorEl &&
              dataInvoces.map((invoce) => (
                <TableRow key={invoce.id}>
                  <TableCell align="left">{invoce.id}</TableCell>
                  <TableCell align="left">{invoce.status}</TableCell>
                  <TableCell align="left">{invoce.subtotal}</TableCell>
                  <TableCell align="left">{invoce.commission}</TableCell>
                  <TableCell align="left">
                    {invoce.due_payments.length && (
                      <Button
                        variant="contained"
                        aria-controls={
                          Boolean(anchorEl[invoce.id])
                            ? "demo-customized-menu"
                            : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={
                          Boolean(anchorEl[invoce.id]) ? "true" : undefined
                        }
                        className="eb__btn"
                        sx={{
                          backgroundColor: "#323131!important",
                        }}
                        onClick={(event) => handleClick(invoce.id, event)}
                      >
                        {`штрафов: ${invoce.due_payments.length}`}
                        <KeyboardArrowDownIcon />
                      </Button>
                    )}
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl[invoce.id]}
                      open={Boolean(anchorEl[invoce.id])}
                      onClose={() => handleClose(invoce.id, null)}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {invoce.due_payments.map((item) => (
                        <MenuItem
                          key={item.id}
                          onClick={() =>
                            handleClose(invoce.id, item.bill_number)
                          }
                        >
                          <ArticleIcon
                            sx={{ color: "#626262", marginRight: "8px" }}
                          />
                          {item.amount}₽
                        </MenuItem>
                      ))}
                    </Menu>
                  </TableCell>
                  <TableCell align="left">{invoce.payment}</TableCell>
                  <TableCell align="left">{invoce.payment_id}</TableCell>
                  <TableCell align="left">
                    {DateTime.fromISO(invoce.created_at)
                      .setLocale("ru")
                      .toLocaleString(DateTime.DATETIME_MED)}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default InvoicesDrawler;
