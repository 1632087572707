import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tableCellClasses,
} from "@mui/material";
import { useUnit } from "effector-react";
import { $user, changeFilter } from "../../store/user";
import SideBar from "../../components/sideBar/ui";
import { styled, alpha } from "@mui/material/styles";
import { fetchFilterUserFx } from "../../api/statistic";
import {
  $statisticsUser,
  DocumentCarType,
  DuePayments,
  InvoicesType,
} from "../../store/statisticsUser";
import { DateTime } from "luxon";
import React, { ReactNode } from "react";
import { $filter } from "../../store/sort";
import Menu, { MenuProps } from "@mui/material/Menu";
import { getDataFilter } from "../../components/drawer/ui";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DocumentsDialog from "../../components/documentsDialog/ui";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import "./style.css";
import InvoicesDrawler from "../../components/invoicesDrawler/ui";

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontFamily: "inherit",
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function debounce(callback: (e: number) => void, e: number, delay: number) {
  let timer: NodeJS.Timeout;
  return function () {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback(e);
    }, delay);
  };
}

const Dashboard = () => {
  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [invoices, setInvoices] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | any>(null);
  const [dataDocument, setDataDocument] =
    React.useState<DocumentCarType | null>(null);
  const [dataInvoces, setDataInvoces] = React.useState<InvoicesType[] | null>(
    null
  );
  const tableData = useUnit($statisticsUser);
  const filters = useUnit($filter);
  const loading = useUnit(fetchFilterUserFx.pending);
  React.useEffect(() => {
    fetchFilterUserFx({ data: null, page: 1 });
  }, []);

  React.useEffect(() => {
    if (tableData) {
      const list: any = {};
      tableData.data.forEach((item) => {
        list[item.id] = null;
      });
      setAnchorEl(list);
    }
  }, [tableData]);

  const handleChangePagination = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    fetchFilterUserFx({ data: getDataFilter(filters), page: value });
  };

  const handleModal = (document: any) => {
    setDataDocument(document);
    setOpen(true);
  };

  const handleInvoces = (invoices: InvoicesType[]) => {
    setDataInvoces(invoices);
    setInvoices(true);
  };

  const handleClick = (id: number, event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl((val: any) => {
      return { ...val, [id]: event.currentTarget };
    });
  };
  const handleClose = (id: number) => {
    setAnchorEl((val: any) => {
      return { ...val, [id]: null };
    });
  };

  const getCountFine = (documents: DocumentCarType[]): number => {
    let count = 0;
    documents.forEach((document) => {
      document.due_payments.forEach((payment) => {
        if (!payment.paid) count += 1;
      });
    });
    return count;
  };

  const getAllCountFine = (documents: DocumentCarType[]): number => {
    let count = 0;
    documents.forEach((document) => {
      document.due_payments.forEach((payment) => {
        count += 1;
      });
    });
    return count;
  };

  const getInfoPayment = (paid_with_us: number): ReactNode => {
    if (paid_with_us === 0)
      return (
        <Tooltip title="Не переходил на оплату">
          <div className="unPaid">{paid_with_us}</div>
        </Tooltip>
      );
    else if (paid_with_us > 0)
      return (
        <Tooltip title="Оплатил у нас">
          <div className="paid">{paid_with_us}</div>
        </Tooltip>
      );
    else if (paid_with_us < 0)
      return (
        <Tooltip title="Перешел на оплату и не оплатил">
          <div className="disabledPaid">{paid_with_us}</div>
        </Tooltip>
      );
  };

  const getData = (e: number) => {
    setPage(e);
    fetchFilterUserFx({ data: getDataFilter(filters), page: e });
  };

  const handleFastPagination = (e: string) => {
    debounce(getData, Number(e.replace(/\D/g, "")), 1000)();
  };
  return (
    <>
      <SideBar tableName="users" />
      {loading ? (
        <div className="loader">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <Container sx={{ marginTop: 10 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Badge
            badgeContent={Object.keys(getDataFilter(filters)).length}
            color="error">
            <Button
              onClick={() => changeFilter(true)}
              variant="contained"
              sx={{
                fontFamily: "inherit",
                marginBottom: "1em",
                gap: "4px",
                background: "#000!important",
                textTransform: "capitalize!important",
              }}
            >
              <FilterAltIcon sx={{ fontSize: "18px" }} />
              Фильтры
            </Button>
          </Badge>
            <h3>Всего пользователей: {tableData?.meta.total || 0}</h3>
          </div>

          {tableData && (
            <>
              <Drawer
                open={open}
                anchor={"right"}
                onClose={() => setOpen(false)}
              >
                {dataDocument && (
                  <DocumentsDialog document={dataDocument} setOpen={setOpen} />
                )}
              </Drawer>
              <Drawer
                open={invoices}
                anchor={"right"}
                onClose={() => setInvoices(false)}
              >
                {dataInvoces && (
                  <InvoicesDrawler
                    dataInvoces={dataInvoces}
                    setInvoices={setInvoices}
                  />
                )}
              </Drawer>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 850 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>ID пользователя</StyledTableCell>
                      <StyledTableCell align="left">Email</StyledTableCell>
                      <StyledTableCell align="left">Документы</StyledTableCell>
                      <StyledTableCell align="center">
                        Всего штрафов
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        Дата добавления
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Оплачивал у нас
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.data.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left" sx={{ fontFamily: "inherit" }}>
                          {row.documents.length && anchorEl ? (
                            row.documents.length < 2 ? (
                              row.documents.map((item) => (
                                <Button
                                  key={item.id}
                                  variant="contained"
                                  sx={{ background: "#000!important" }}
                                  onClick={() => handleModal(row.documents[0])}
                                  className="eb__btn"
                                >
                                  <AssignmentIcon
                                    sx={{ color: "#fff", fontSize: "18px" }}
                                  />
                                  {item.code}
                                </Button>
                              ))
                            ) : (
                              <>
                                <Button
                                  id="demo-customized-button"
                                  aria-controls={
                                    Boolean(anchorEl[row.id])
                                      ? "demo-customized-menu"
                                      : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={
                                    Boolean(anchorEl[row.id])
                                      ? "true"
                                      : undefined
                                  }
                                  variant="contained"
                                  disableElevation
                                  onClick={(event) =>
                                    handleClick(row.id, event)
                                  }
                                  className="eb__btn"
                                  sx={{
                                    backgroundColor: "#323131!important",
                                  }}
                                  endIcon={<KeyboardArrowDownIcon />}
                                >
                                  Документов - {row.documents.length}
                                </Button>
                                <StyledMenu
                                  id="demo-customized-menu"
                                  MenuListProps={{
                                    "aria-labelledby": "demo-customized-button",
                                  }}
                                  anchorEl={anchorEl[row.id]}
                                  open={Boolean(anchorEl[row.id])}
                                  onClick={() => handleClose(row.id)}
                                >
                                  {row.documents.map((item) => (
                                    <MenuItem
                                      key={item.id}
                                      onClick={() => {
                                        handleClose(row.id);
                                        handleModal(item);
                                      }}
                                      disableRipple
                                    >
                                      <AssignmentIcon
                                        sx={{ color: "#fff", fontSize: "18px" }}
                                      />
                                      {item.code}
                                    </MenuItem>
                                  ))}
                                </StyledMenu>
                              </>
                            )
                          ) : (
                            <div style={{ fontWeight: "500" }}>
                              Документов нет
                            </div>
                          )}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            whiteSpace: "nowrap",
                            fontFamily: "inherit",
                            fontWeight: "500",
                          }}
                        >
                          <div className="containerCountFine">
                            <Tooltip title="Неоплаченные штрафов">
                              <div className="countUnPaidFine">
                                {getCountFine(row.documents)}
                              </div>
                            </Tooltip>
                            <Tooltip title="Всего штрафов">
                              <div className="countPaidFine">
                                {getAllCountFine(row.documents)}
                              </div>
                            </Tooltip>
                          </div>
                        </TableCell>
                        <TableCell align="left" sx={{ whiteSpace: "nowrap" }}>
                          {DateTime.fromISO(row.created_at)
                            .setLocale("ru")
                            .toLocaleString(DateTime.DATETIME_MED)}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleInvoces(row.invoices)}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {getInfoPayment(row.paid_with_us)}
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={{
                  marginTop: 4,
                  marginBottom: 4,
                  display: "flex",
                  gap: "1em",
                }}
              >
                <Pagination
                  onChange={handleChangePagination}
                  count={tableData.meta.last_page}
                  page={page}
                />
                <input
                  className="fastPagination"
                  onChange={(e) => handleFastPagination(e.target.value)}
                />
              </Box>
            </>
          )}
        </Container>
      )}
    </>
  );
};

export default Dashboard;
