import { createEvent, createStore } from "effector";
import { DateTime } from "luxon";

export interface Filter {
    from: string | null
    to: string | null
    id: string | null
    name: string | null
    email: string | null
    car_number: string | null
    code: string | null
    admin: boolean | null
    subscribed: boolean | null
    sort: {
        by: string | null
        direction: string | null
    } | null
    invoices_count_min: number | null
    invoices_count: number | null
    invoice_from: string | null
    invoice_to: string | null
}

export interface FilterInvoices {
    from: string | null
    to: string | null
    id: string | null
    amount_from: string | null
    amount_to: string | null
    email: string | null
    user_id: string | null
    status: string | null
    sort: {
        by: string | null
        direction: string | null
    } | null
}

const filter = {
    from: null,
    to: null,
    id: null,
    name: null,
    email: null,
    car_number: null,
    code: null,
    admin: null,
    subscribed: null,
    sort: null,
    invoices_count_min: null,
    invoices_count: null,
    invoice_from: null,
    invoice_to: null
}

const filterInvoices = {
    from: null,
    to: null,
    id: null,
    amount_from: null,
    amount_to: null,
    email: null,
    user_id: null,
    status: null,
    sort: null
}

interface changeFormType {
    label: string
    value: string | boolean
    secondLabel?: string | null
}

export const changeForm = createEvent<changeFormType>();
export const resetSort = createEvent();

export const changeFormInvoices = createEvent<changeFormType>();
export const resetSortInvoices = createEvent();


export const $filter = createStore<Filter>(filter)
 .on(
    changeForm,
    (state, result) => {
        if(result.secondLabel)
            return {...state, [result.label]: {...state.sort, [result.secondLabel]: result.value}}
        else
            return {...state, [result.label]: result.value}
    }
  )
 .on(
    resetSort,
    (state, result) => {
        return filter
    }
  )

  export const $filterInvoices = createStore<FilterInvoices>(filterInvoices)
  .on(
    changeFormInvoices,
     (state, result) => {
         if(result.secondLabel)
             return {...state, [result.label]: {...state.sort, [result.secondLabel]: result.value}}
         else
             return {...state, [result.label]: result.value}
     }
   )
  .on(
    resetSortInvoices,
     (state, result) => {
         return filterInvoices
     }
   )

