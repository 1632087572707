import { RouterProvider } from "react-router-dom";
import { router } from "./page/router";
import React from "react";
import { csrfTokenFx, fetchUserFx } from "./api/user";
import { CircularProgress } from "@mui/material";
import { useUnit } from "effector-react";

const App = () => {
  const loading = useUnit(fetchUserFx.pending);

  async function getUser() {
    await csrfTokenFx();
    try {
      await fetchUserFx();
    } catch (e) {}
  }

  React.useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader">
          <CircularProgress color="inherit" />
        </div>
      ) : (
        <RouterProvider router={router} />
      )}
    </>
  );
};

export default App;
