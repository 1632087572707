import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useUnit } from "effector-react";
import { $filter, $filterInvoice, $user, changeFilter, changeFilterInvoice } from "../../store/user";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import DrawerAdmin from "../drawer/ui";
import DrawerInvoice from "../drawerInvoice/ui";
import { Link } from "react-router-dom";

const menu = [
  { id: 1, label: "Пользователи", href: "/users" },
  { id: 2, label: "email", href: "/email" },
  { id: 3, label: "Оплаты", href: "/invoices" },
];

interface SideBarProps {
  tableName: string;
}

const SideBar = ({ tableName }: SideBarProps) => {
  const filter = useUnit($filter);
  const filterInvoice = useUnit($filterInvoice);

  const toggleDrawer = (val: boolean) => {
    changeFilter(val);
    changeFilterInvoice(val);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: "#000" }}>
      <Container maxWidth="xl">
        <Toolbar>
          <img src="./favicon.ico" />
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex", justifyContent: "flex-end" },
            }}
          >
            {menu.map((menuItem) => (
              <Link
                key={menuItem.id}
                to={menuItem.href}
                style={{ textDecoration: "none" }}
              >
                <Button
                  sx={{
                    my: 2,
                    color: "white",
                    display: "block",
                    fontWeight: "700",
                  }}
                >
                  {menuItem.label}
                </Button>
              </Link>
            ))}
          </Box>
        </Toolbar>
      </Container>
      <DrawerAdmin
        tableName={tableName}
        open={filter}
        toggleDrawer={toggleDrawer}
      />
      <DrawerInvoice
        tableName={tableName}
        open={filterInvoice}
        toggleDrawer={toggleDrawer}
      />
    </AppBar>
  );
};

export default SideBar;
