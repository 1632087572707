import {
  Button,
  Drawer,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from "@mui/material";
import {
  LocalizationProvider,
  DateTimeField,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { $filter, Filter, changeForm, resetSort } from "../../store/sort";
import { useUnit } from "effector-react";
import { fetchFilterUserFx } from "../../api/statistic";
import { DateTime } from "luxon";

interface DrawerAdminProps {
  tableName: string;
  open: boolean;
  toggleDrawer: (val: boolean) => void;
}

export const getDataFilter = (filters: Filter) => {
  let dataFilter = {};
  for (const key in filters) {
    if (filters[key as keyof Filter])
      dataFilter = { ...dataFilter, [key]: filters[key as keyof Filter] };
  }
  return dataFilter;
};

const DrawerAdmin = ({ tableName, open, toggleDrawer }: DrawerAdminProps) => {
  const filters = useUnit($filter);

  const handleSort = (
    e: SelectChangeEvent<unknown>,
    label: string,
    secondLabel?: string
  ) => {
    changeForm({
      value: e.target.value as string,
      label: label,
      secondLabel: secondLabel,
    });
  };

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    label: string
  ) => {
    changeForm({ value: e.target.value, label: label });
  };

  const handleChangeSwitch = (
    e: React.ChangeEvent<HTMLInputElement>,
    label: string
  ) => {
    changeForm({ value: Boolean(e.target.value), label: label });
  };

  const handleDateTime = (e: any, label: string) => {
    changeForm({ value: e.toISO(), label: label });
  };

  const handleClick = () => {
    const dataFilter = getDataFilter(filters);
    switch (tableName) {
      case "users":
        fetchFilterUserFx({ data: dataFilter, page: 1 });
    }

    toggleDrawer(false);
  };

  const handleResetClick = () => {
    switch (tableName) {
      case "users":
        fetchFilterUserFx({ data: null, page: 1 });
    }
    resetSort();
    toggleDrawer(false);
  };

  return (
    <Drawer anchor="left" open={open} onClose={() => toggleDrawer(false)}>
      <div
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div style={{ display: "flex", gap: "20px" }}>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <Switch
              onChange={(e) => handleChangeSwitch(e, "admin")}
              defaultChecked={!!filters.admin}
            />
            <FormHelperText id="component-helper-text">
              Пользователь является админом
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <Switch
              onChange={(e) => handleChangeSwitch(e, "subscribed")}
              defaultChecked={!!filters.subscribed}
            />
            <FormHelperText id="component-helper-text">
              Пользователь подписан
            </FormHelperText>
          </FormControl>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="id"
              value={filters.id}
              variant="outlined"
              onChange={(e) => handleInput(e, "id")}
            />
            <FormHelperText id="component-helper-text">
              Поиск по id Пользователя
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="Имя"
              value={filters.name}
              variant="outlined"
              onChange={(e) => handleInput(e, "name")}
            />
            <FormHelperText id="component-helper-text">
              Поиск по имени пользователя
            </FormHelperText>
          </FormControl>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <FormControl variant="standard">
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  onChange={(e) => handleDateTime(e, "from")}
                  label="Время начала"
                  value={!!filters.from ? DateTime.fromISO(filters.from) : null}
                  ampm={false}
                />
              </DemoContainer>
            </LocalizationProvider>
            <FormHelperText id="component-helper-text">
              Дата создания пользователя
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard">
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  onChange={(e) => handleDateTime(e, "to")}
                  value={!!filters.to ? DateTime.fromISO(filters.to) : null}
                  label="Время конца"
                  ampm={false}
                />
              </DemoContainer>
            </LocalizationProvider>
          </FormControl>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="Номер"
              value={filters.car_number}
              variant="outlined"
              onChange={(e) => handleInput(e, "car_number")}
            />
            <FormHelperText id="component-helper-text">
              Поиск по номеру автомобиля
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="СТС/ВУ"
              value={filters.code}
              variant="outlined"
              onChange={(e) => handleInput(e, "code")}
            />
            <FormHelperText id="component-helper-text">
              Поиск по номеру СТС/ВУ
            </FormHelperText>
          </FormControl>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label" variant="outlined">
              По умолчанию
            </InputLabel>
            <Select
              label="По умолчанию"
              variant="outlined"
              defaultValue={filters["sort"] ? filters["sort"].by : null}
              onChange={(e) => handleSort(e, "sort", "by")}
            >
              <MenuItem value={"name"}>name</MenuItem>
              <MenuItem value={"email"}>email</MenuItem>
              <MenuItem value={"created_at"}>created_at</MenuItem>
              <MenuItem value={"updated_at"}>updated_at</MenuItem>
              <MenuItem value={"invoices_count"}>invoices_count</MenuItem>
            </Select>
            <FormHelperText id="component-helper-text">
              Сортировать по полю
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label" variant="outlined">
              По умолчанию
            </InputLabel>
            <Select
              label="По умолчанию"
              variant="outlined"
              defaultValue={filters["sort"] ? filters["sort"].direction : null}
              onChange={(e) => handleSort(e, "sort", "direction")}
            >
              <MenuItem value={"asc"}>По возрастанию</MenuItem>
              <MenuItem value={"desc"}>По убыванию</MenuItem>
            </Select>
            <FormHelperText id="component-helper-text">
              Сортировать по возрастанию, убыванию
            </FormHelperText>
          </FormControl>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="Email"
              value={filters.email}
              variant="outlined"
              onChange={(e) => handleInput(e, "email")}
            />
            <FormHelperText id="component-helper-text">
              Поиск по email пользователя
            </FormHelperText>
          </FormControl>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="Количество"
              value={filters.invoices_count_min}
              variant="outlined"
              onChange={(e) => handleInput(e, "invoices_count_min")}
            />
            <FormHelperText id="component-helper-text">
              Минимальное количество оплат
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="Количество"
              value={filters.invoices_count}
              variant="outlined"
              onChange={(e) => handleInput(e, "invoices_count")}
            />
            <FormHelperText id="component-helper-text">
              Всего оплат
            </FormHelperText>
          </FormControl>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <FormControl variant="standard">
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  onChange={(e) => handleDateTime(e, "invoice_from")}
                  label="Время начала"
                  value={!!filters.invoice_from ? DateTime.fromISO(filters.invoice_from) : null}
                  ampm={false}
                />
              </DemoContainer>
            </LocalizationProvider>
            <FormHelperText id="component-helper-text">
              Дата создания оплаты
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard">
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  onChange={(e) => handleDateTime(e, "invoice_to")}
                  value={!!filters.invoice_to ? DateTime.fromISO(filters.invoice_to) : null}
                  label="Время конца"
                  ampm={false}
                />
              </DemoContainer>
            </LocalizationProvider>
          </FormControl>
        </div>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <Button
            variant="contained"
            onClick={handleClick}
            style={{
              background: "#000",
              fontFamily: "inherit",
              fontWeight: 700,
              padding: "15px 40px",
            }}
          >
            Сортировка
          </Button>
          <Button
            variant="text"
            onClick={handleResetClick}
            style={{
              color: "#000",
              fontFamily: "inherit",
              fontWeight: 700,
              padding: "15px 40px",
            }}
          >
            Cбросить
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default DrawerAdmin;
