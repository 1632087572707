import {
    Badge,
    Box,
    Button,
    CircularProgress,
    Container,
    Drawer,
    MenuItem,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    tableCellClasses,
  } from "@mui/material";
  import { useUnit } from "effector-react";
  import { changeFilterInvoice } from "../../store/user";
  import SideBar from "../../components/sideBar/ui";
  import { styled, alpha } from "@mui/material/styles";
  import { fetchFilterInvoiceFx } from "../../api/invoicesStatistic";
  import { $statisticsInvoices, InvoicesType } from "../../store/statisticsUser";
  import { DateTime } from "luxon";
  import React, { ReactNode } from "react";
  import { $filterInvoices } from "../../store/sort";
  import Menu, { MenuProps } from "@mui/material/Menu";
  import { getDataFilter } from "../../components/drawerInvoice/ui";
  import InvoicesDialog from "../../components/invoicesDialog/ui";
  import FilterAltIcon from "@mui/icons-material/FilterAlt";
  import "./style.css";
  
  export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontFamily: "inherit",
      whiteSpace: "nowrap",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));
  
  function debounce(callback: (e: number) => void, e: number, delay: number) {
    let timer: NodeJS.Timeout;
    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback(e);
      }, delay);
    };
  }
  
  const Invoices = () => {
    const [open, setOpen] = React.useState(false);
    const [page, setPage] = React.useState(1);
    const [invoices, setInvoices] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | any>(null);
    const [dataDocument, setDataDocument] =
    React.useState<InvoicesType | null>(null);
    const tableData = useUnit($statisticsInvoices);
    const filters = useUnit($filterInvoices);
    const loading = useUnit(fetchFilterInvoiceFx.pending);
    React.useEffect(() => {
      fetchFilterInvoiceFx({ data: null, page: 1 });
    }, []);
  
    React.useEffect(() => {
      if (tableData) {
        const list: any = {};
        tableData.data.forEach((item) => {
          list[item.id] = null;
        });
        setAnchorEl(list);
      }
    }, [tableData]);
  
    const handleChangePagination = (
      event: React.ChangeEvent<unknown>,
      value: number
    ) => {
      setPage(value);
      fetchFilterInvoiceFx({ data: getDataFilter(filters), page: value });
    };
  
    const handleModal = (document: any) => {
      setDataDocument(document);
      setOpen(true);
    };

  
    const handleClick = (id: number, event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl((val: any) => {
        return { ...val, [id]: event.currentTarget };
      });
    };
    const handleClose = (id: number) => {
      setAnchorEl((val: any) => {
        return { ...val, [id]: null };
      });
    };
 
  
    const getData = (e: number) => {
      setPage(e);
      fetchFilterInvoiceFx({ data: getDataFilter(filters), page: e });
    };
  
    const handleFastPagination = (e: string) => {
      debounce(getData, Number(e.replace(/\D/g, "")), 1000)();
    };
    return (
      <>
        <SideBar tableName="users" />
        {loading ? (
          <div className="loader">
            <CircularProgress color="inherit" />
          </div>
        ) : (
          <Container sx={{ marginTop: 10 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Badge
                badgeContent={Object.keys(getDataFilter(filters)).length}
                color="error"
              >
                <Button
                  onClick={() => changeFilterInvoice(true)}
                  variant="contained"
                  sx={{
                    fontFamily: "inherit",
                    marginBottom: "1em",
                    gap: "4px",
                    background: "#000!important",
                    textTransform: "capitalize!important",
                  }}
                >
                  <FilterAltIcon sx={{ fontSize: "18px" }} />
                  Фильтры
                </Button>
              </Badge>
              <h3>Всего оплат: {tableData?.meta.total || 0}</h3>
            </div>
  
            {tableData && (
              <>
                <Drawer
                open={open}
                anchor={"right"}
                onClose={() => setOpen(false)}
              >
                {dataDocument && (
                  <InvoicesDialog document={dataDocument} setOpen={setOpen} />
                )}
              </Drawer>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 850 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>ID оплаты</StyledTableCell>
                        <StyledTableCell align="left">Email</StyledTableCell>
                        <StyledTableCell align="center">Всего штрафов</StyledTableCell>
                        <StyledTableCell align="left">Статус</StyledTableCell>
                        <StyledTableCell align="center">Дата добавления</StyledTableCell>
                        <StyledTableCell align="center">Сумма без комиссии</StyledTableCell>
                        <StyledTableCell align="center">Комиссия</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tableData.data.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}>

                          <TableCell align="left" component="th" scope="row">
                            {row.id}
                          </TableCell>
                          <TableCell align="left" component="th" scope="row">
                            {row.user.email}
                          </TableCell>
                          <TableCell
                          align="center"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleModal(row)}
                          sx={{
                            whiteSpace: "nowrap",
                            fontFamily: "inherit",
                            fontWeight: "500",
                          }}>
                          <div className="containerCountFine">
                            {row.status == "Created"?
                            <Tooltip title="">
                              <div className="countUnPaidFine">
                                {row.due_payments.length}
                              </div>
                            </Tooltip>
                            :
                            <Tooltip title="">
                              <div className="countPaidFine">
                                {row.due_payments.length}
                              </div>
                            </Tooltip>
                            }
                          </div>
                          </TableCell>
                          <TableCell align="left" component="th" scope="row">
                            {row.status == "Created"&&
                            <p>Создан</p>
                            }
                            {row.status == "Paid"&&
                            <p>Оплачен</p>
                            }
                          </TableCell>
                          <TableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                            {DateTime.fromISO(row.created_at)
                              .setLocale("ru")
                              .toLocaleString(DateTime.DATETIME_MED)}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.subtotal}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.commission}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box
                  sx={{
                    marginTop: 4,
                    marginBottom: 4,
                    display: "flex",
                    gap: "1em",
                  }}
                >
                  <Pagination
                    onChange={handleChangePagination}
                    count={tableData.meta.last_page}
                    page={page}
                  />
                  <input
                    className="fastPagination"
                    onChange={(e) => handleFastPagination(e.target.value)}
                  />
                </Box>
              </>
            )}
          </Container>
        )}
      </>
    );
  };
  
  export default Invoices;
  