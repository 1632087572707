import { createEvent, createStore } from "effector";
import { authFx, fetchUserFx } from "../api/user";

interface User {
    id: string
}

authFx.done.watch(({ params, result }) => {
    fetchUserFx()
});

export const changeFilter = createEvent<boolean>()
export const changeFilterInvoice = createEvent<boolean>()

export const $filter = createStore<boolean>(false)
    .on(changeFilter, (_, result) => result)

export const $filterInvoice = createStore<boolean>(false)
    .on(changeFilterInvoice, (_, result) => result)
  
export const $user = createStore<User | null>(null).on(
    fetchUserFx.doneData,
    (_, result) => result
  );