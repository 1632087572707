import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Button,
  Box,
  DialogTitle,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Switch,
  Tooltip,
} from "@mui/material";
import { StyledTableCell } from "../../page/dashboard/ui";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { InvoicesType } from "../../store/statisticsUser";
import DoneIcon from "@mui/icons-material/Done";
import "./style.css";

interface InvoicesDialogType {
  document: InvoicesType;
  setOpen: (val: boolean) => void;
}

const InvoicesDialog = ({ document, setOpen }: InvoicesDialogType) => {
  const [fines, setFines] = React.useState(document.due_payments);
  console.log(fines)

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   if (e.target.checked)
  //     setFines(document.due_payments.filter((item) => item.paid !== true));
  //   else setFines(document.due_payments);
  // };

  // const getAllCountFine = (fines: InvoicesType): number => {
  //   let count = 0;
  //   fines.due_payments.forEach((fine) => {
  //     count += 1;
  //   });

  //   return count;
  // };

  return (
    <Box sx={{ margin: 6 }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 850 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell align="left">Номер</StyledTableCell>
              <StyledTableCell align="left">Оплачен</StyledTableCell>
              <StyledTableCell align="center">Сумма</StyledTableCell>
              <StyledTableCell align="center" sx={{ whiteSpace: "nowrap" }}>
                Дата скидки
              </StyledTableCell>
              <StyledTableCell align="center">Дата штрафа</StyledTableCell>
              <StyledTableCell align="center" sx={{ maxWidth: "150px" }}>
                Место Нарушения
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ whiteSpace: "nowrap", maxWidth: "150px" }}
              >
                Акт
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{ whiteSpace: "nowrap", maxWidth: "150px" }}
              >
                Организация
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fines.map((payments) => (
              <TableRow>
                <TableCell align="left">{payments.id}</TableCell>
                <TableCell align="left">{payments.bill_number}</TableCell>
                <TableCell align="center">
                  <div
                    style={{
                      display: "flex",
                      gap: "4px",
                    }}
                  >
                    {payments.paid ? (
                      <Tooltip title="Оплачен">
                        <CheckCircleIcon color="success" />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Не оплачен">
                        <DoDisturbOnIcon sx={{ color: "#BD362C" }} />
                      </Tooltip>
                    )}
                    {payments.paid &&
                      (payments.paid_by_user ? (
                        <Tooltip title="Оплачен у нас">
                          <DoneIcon />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Оплачен не у нас">
                          <CloseIcon />
                        </Tooltip>
                      ))}
                  </div>
                </TableCell>
                <TableCell align="left" sx={{ fontFamily: "inherit" }}>
                  <div>
                    {Number(payments.discount) > 0 ? (
                      <div className="priceContainer">
                        <span className="oldPrice">
                          {Number(payments.amount)}
                        </span>
                        <span className="price">
                          {Number(payments.amount) - Number(payments.discount)}₽
                        </span>
                      </div>
                    ) : (
                      <span className="price">{Number(payments.amount)}₽</span>
                    )}
                  </div>
                </TableCell>
                <TableCell align="left">{payments.discount_till}</TableCell>
                <TableCell align="left">{payments.bill_date}</TableCell>
                <TableCell align="left" sx={{ maxWidth: "150px" }}>
                  {payments.offense_place}
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: "150px" }}>
                  {payments.legal_act}
                </TableCell>
                <TableCell align="left" sx={{ maxWidth: "150px" }}>
                  {payments.department_name}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InvoicesDialog;
