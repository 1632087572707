import { Alert, Button, Container, Paper, TextField } from "@mui/material";
import SideBar from "../../components/sideBar/ui";
import React from "react";
import { fetchEmailFx } from "../../api/user";

const Emails = () => {
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState("");
  const handleSend = async () => {
    try {
      await fetchEmailFx(email);
      setEmail("");
    } catch (e: any) {
      setError("не отправлено");
    }
  };
  return (
    <>
      <SideBar tableName="users" />
      <Container sx={{ marginTop: 8 }}>
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <Paper sx={{ p: 2, display: "grid", gap: "1em" }} variant="outlined">
            <h4>Отправить все письма по штрафам на введнный email</h4>
            {error && <Alert severity="error">{error}</Alert>}
            <TextField
              id="filled-basic"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              label="Email"
              variant="filled"
            />
            <Button
              variant="contained"
              className="eb__btn"
              onClick={handleSend}
              sx={{
                backgroundColor: "#323131!important",
              }}
            >
              Отправить
            </Button>
          </Paper>
        </div>
      </Container>
    </>
  );
};

export default Emails;
