import {
  Button,
  Container,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import "./style.css";
import React from "react";
import { validate } from "../../components/validation/validate";
import axios, { AxiosError } from "axios";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import fone from "../../assets/img/fon-auth.jpg";
import rightBg from "../../assets/img/bg-auth.jpg";
import { useUnit } from "effector-react";
import { $user } from "../../store/user";
import { authFx, fetchUserFx } from "../../api/user";
import { Navigate, useNavigate } from "react-router-dom";

export const baseUrl = process.env.NEXT_PUBLIC_BACKEND_SERVER_HOST
  ? process.env.NEXT_PUBLIC_BACKEND_SERVER_HOST
  : "";

interface authField {
  username: string;
  password: string;
}

interface validAuthField {
  [key: string]: {
    isValid: null | boolean;
    error?: string;
  };
}

const AuthForm = () => {
  const [authField, setAuthField] = React.useState<authField>({
    username: "",
    password: "",
  });
  const [validateField, setValidateField] = React.useState<validAuthField>({
    username: { isValid: null },
    password: { isValid: null },
  });

  React.useEffect(() => {
    document.title = "Штраф онлайн авторизация";
  }, []);

  const navigate = useNavigate();
  const user = useUnit($user);
  if (user) return <Navigate to="/users" replace={true} />;

  const handleValidField = (type: "username" | "password", value: string) => {
    const valid = validate(type, value);
    if (valid?.isValid) {
      setValidateField({
        ...validateField,
        [type]: { isValid: valid.isValid },
      });
    } else {
      setValidateField({
        ...validateField,
        [type]: { isValid: valid.isValid, error: valid?.error },
      });
    }
  };

  const authentication = async () => {
    try {
      await authFx({ email: authField.username, password: authField.password });
      navigate("/users");
    } catch (e) {
      if (axios.isAxiosError(e)) {
        setValidateField({
          ...validateField,
          username: {
            isValid: false,
            error: !!e.response ? e.response.data.message : "unknown error",
          },
        });
      } else {
        setValidateField({
          ...validateField,
          username: {
            isValid: false,
            error: "unknown error",
          },
        });
      }
    }
  };

  return (
    <div className={"mainAuth"}>
      <Container maxWidth="lg" style={{ zIndex: 10 }}>
        <div className={"authForm"}>
          <div className={"authForm__content"}>
            <div className={"authForm__back"}>
              <Button
                variant="text"
                href="https://xn--80a5afkq.xn--80asehdb/"
                className={"authForm__backBtn"}
              >
                <ArrowBackIosNewIcon sx={{ fontSize: "18px" }} />
                назад
              </Button>
            </div>

            <div className={"authForm__auth"}>
              <div className={"authForm__description"}>
                <h2 className={"authForm__title"}>Авторизация</h2>
                <p className={"authForm__subTitle"}>
                  Авторизуйтесь для того, чтобы увидеть свой профиль и документы
                </p>
              </div>
              <div className={"authForm__body"}>
                <FormControl
                  sx={{ width: "100%" }}
                  variant="filled"
                  error={
                    !validateField.username.isValid &&
                    validateField.username.isValid !== null
                      ? true
                      : false
                  }
                >
                  <TextField
                    autoComplete="off"
                    value={authField.username}
                    onChange={(event) =>
                      setAuthField({
                        ...authField,
                        username: event.target.value,
                      })
                    }
                    label="Email"
                    variant="filled"
                    onBlur={(event) =>
                      handleValidField("username", event.target.value)
                    }
                  />
                  {validateField?.username?.error && (
                    <FormHelperText id="password">
                      {validateField.username.error}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl
                  sx={{ width: "100%" }}
                  variant="filled"
                  error={
                    !validateField.username.isValid &&
                    validateField.username.isValid !== null
                      ? true
                      : false
                  }
                >
                  <TextField
                    autoComplete="off"
                    type="password"
                    value={authField.password}
                    onChange={(event) =>
                      setAuthField({
                        ...authField,
                        password: event.target.value,
                      })
                    }
                    label="Пароль"
                    variant="filled"
                    onBlur={(event) =>
                      handleValidField("password", event.target.value)
                    }
                  />
                  {validateField?.password?.error && (
                    <FormHelperText id="password">
                      {validateField.password.error}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
              <div className={"authForm__controller"}>
                <Button
                  onClick={authentication}
                  variant="contained"
                  className={"authForm__btn"}
                  style={{ height: "50px", width: "250px" }}
                >
                  Войти
                </Button>
              </div>
            </div>
          </div>
          <div className={"authForm__decaor"}>
            <div
              className={"authForm__img"}
              style={{ backgroundImage: `url(${rightBg})` }}
            />
            <div className={"authForm__decaorSocial"}>
              <div className={"socialDescription"}>
                <span className={"socialDescription__span"}>Подпишись:</span>
                <div>
                  <Button
                    variant="text"
                    color="secondary"
                    sx={{ minWidth: "38px" }}
                  ></Button>
                  <Button
                    variant="text"
                    color="secondary"
                    sx={{ minWidth: "38px" }}
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className={"mainAuthEdge"}>
        <div
          className={"mainAuthEdge__fone"}
          style={{
            backgroundImage: `url(${fone})`,
          }}
        />
        <div className={"mainAuthEdge__gradient"}></div>
      </div>
    </div>
  );
};
export default AuthForm;
