import {
  Button,
  Drawer,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
} from "@mui/material";
import {
  LocalizationProvider,
  DateTimeField,
  DateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { $filterInvoices, FilterInvoices, changeFormInvoices, resetSortInvoices } from "../../store/sort";
import { useUnit } from "effector-react";
import { fetchFilterInvoiceFx } from "../../api/invoicesStatistic";
import { DateTime } from "luxon";

interface DrawerInvoiceProps {
  tableName: string;
  open: boolean;
  toggleDrawer: (val: boolean) => void;
}

export const getDataFilter = (filters: FilterInvoices) => {
  let dataFilter = {};
  for (const key in filters) {
    if (filters[key as keyof FilterInvoices])
      dataFilter = { ...dataFilter, [key]: filters[key as keyof FilterInvoices] };
  }
  return dataFilter;
};

const DrawerInvoice = ({ tableName, open, toggleDrawer }: DrawerInvoiceProps) => {
  const filters = useUnit($filterInvoices);

  const handleSort = (
    e: SelectChangeEvent<unknown>,
    label: string,
    secondLabel?: string
  ) => {
    changeFormInvoices({
      value: e.target.value as string,
      label: label,
      secondLabel: secondLabel,
    });
  };

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    label: string
  ) => {
    changeFormInvoices({ value: e.target.value, label: label });
  };

  const handleDateTime = (e: any, label: string) => {
    changeFormInvoices({ value: e.toISO(), label: label });
  };

  const handleClick = () => {
    const dataFilter = getDataFilter(filters);
    switch (tableName) {
      case "users":
        fetchFilterInvoiceFx({ data: dataFilter, page: 1 });
    }

    toggleDrawer(false);
  };

  const handleResetClick = () => {
    switch (tableName) {
      case "users":
        fetchFilterInvoiceFx({ data: null, page: 1 });
    }
    resetSortInvoices();
    toggleDrawer(false);
  };

  return (
    <Drawer anchor="left" open={open} onClose={() => toggleDrawer(false)}>
      <div
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <div style={{ display: "flex", gap: "20px" }}>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="id оплаты"
              value={filters.id}
              variant="outlined"
              onChange={(e) => handleInput(e, "id")}
            />
            <FormHelperText id="component-helper-text">
              Поиск по id оплаты
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="id пользователя"
              value={filters.user_id}
              variant="outlined"
              onChange={(e) => handleInput(e, "user_id")}
            />
            <FormHelperText id="component-helper-text">
              Поиск по id пользователя
            </FormHelperText>
          </FormControl>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <FormControl variant="standard">
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  onChange={(e) => handleDateTime(e, "from")}
                  label="Время начала"
                  value={!!filters.from ? DateTime.fromISO(filters.from) : null}
                  ampm={false}
                />
              </DemoContainer>
            </LocalizationProvider>
            <FormHelperText id="component-helper-text">
              Дата создания оплаты
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard">
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DemoContainer components={["DateTimePicker"]}>
                <DateTimePicker
                  onChange={(e) => handleDateTime(e, "to")}
                  value={!!filters.to ? DateTime.fromISO(filters.to) : null}
                  label="Время конца"
                  ampm={false}
                />
              </DemoContainer>
            </LocalizationProvider>
          </FormControl>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="Сумма от"
              value={filters.amount_from}
              variant="outlined"
              onChange={(e) => handleInput(e, "amount_from")}
            />
            <FormHelperText id="component-helper-text">
              Поиск по сумме от 
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="Сумма до"
              value={filters.amount_to}
              variant="outlined"
              onChange={(e) => handleInput(e, "amount_to")}
            />
            <FormHelperText id="component-helper-text">
              Поиск по сумме до
            </FormHelperText>
          </FormControl>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label" variant="outlined">
              По умолчанию
            </InputLabel>
            <Select
              label="По умолчанию"
              variant="outlined"
              defaultValue={filters["sort"] ? filters["sort"].by : null}
              onChange={(e) => handleSort(e, "sort", "by")}
            >
              <MenuItem value={"email"}>email</MenuItem>
              <MenuItem value={"total_amount"}>total_amount</MenuItem>
              <MenuItem value={"created_at"}>created_at</MenuItem>
              <MenuItem value={"updated_at"}>updated_at</MenuItem>
            </Select>
            <FormHelperText id="component-helper-text">
              Сортировать по полю
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-label" variant="outlined">
              По умолчанию
            </InputLabel>
            <Select
              label="По умолчанию"
              variant="outlined"
              defaultValue={filters["sort"] ? filters["sort"].direction : null}
              onChange={(e) => handleSort(e, "sort", "direction")}
            >
              <MenuItem value={"asc"}>По возрастанию</MenuItem>
              <MenuItem value={"desc"}>По убыванию</MenuItem>
            </Select>
            <FormHelperText id="component-helper-text">
              Сортировать по возрастанию, убыванию
            </FormHelperText>
          </FormControl>
        </div>
        <div style={{ display: "flex", gap: "20px" }}>
          <FormControl variant="standard" style={{ width: "100%" }}>
            <TextField
              id="outlined-basic"
              label="Email"
              value={filters.id}
              variant="outlined"
              onChange={(e) => handleInput(e, "email")}
            />
            <FormHelperText id="component-helper-text">
              Поиск по email пользователя
            </FormHelperText>
          </FormControl>
          <FormControl variant="standard" style={{ width: "100%" }}>
          <InputLabel id="demo-simple-select-label" variant="outlined">
              Статус
            </InputLabel>
            <Select
              label="Статус"
              variant="outlined"
              value={filters.status || ''}
              onChange={(e) => handleSort(e, "status")}
            >
              <MenuItem value={"1"}>Создан</MenuItem>
              <MenuItem value={"2"}>Оплачен</MenuItem>
              <MenuItem value={"3"}>Отменен</MenuItem>
            </Select>
            <FormHelperText id="component-helper-text">
              Поиск по статусу
            </FormHelperText>
          </FormControl>
        </div>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
          <Button
            variant="contained"
            onClick={handleClick}
            style={{
              background: "#000",
              fontFamily: "inherit",
              fontWeight: 700,
              padding: "15px 40px",
            }}
          >
            Сортировка
          </Button>
          <Button
            variant="text"
            onClick={handleResetClick}
            style={{
              color: "#000",
              fontFamily: "inherit",
              fontWeight: 700,
              padding: "15px 40px",
            }}
          >
            Cбросить
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default DrawerInvoice;
