import { createBrowserRouter } from "react-router-dom";
import AuthForm from "./auth/ui";
import Dashboard from "./dashboard/ui";
import PrivateRoutes from "../components/privateRoutes/ui";
import Emails from "./emails/ui";
import Invoices from "./invoices/ui"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <PrivateRoutes />,
    children: [
      {
        path: "/users",
        element: <Dashboard />,
      },
      {
        path: "/email",
        element: <Emails />,
      },
      {
        path: "/invoices",
        element: <Invoices />,
      },
    ],
  },
  {
    path: "/login",
    element: <AuthForm />,
  },
]);
